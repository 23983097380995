import useSWR from 'swr'
import { requestWithCache } from 'lib/api'
import { ENDPOINTS } from 'lib/api/const'

function useBestsellers(categoryId, limit = 4) {
  const bestsellerParams = {
    params: {
      limit,
    },
  }

  const { data, error } = useSWR(
    [ENDPOINTS.bestsellers.link(categoryId), JSON.stringify(bestsellerParams)],
    requestWithCache,
    {
      dedupingInterval: ENDPOINTS.bestsellers.cache,
    },
  )

  return { data: data?.products, error }
}

export default useBestsellers
